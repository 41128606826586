.section_unisearch_university {
  padding-top: 100px;
}

.title_wrapper {
  margin-right: 100px;
  .sub_title {
    font-weight: normal;
    color: #828282;
    text-align: justify;
  }
  .title {
    margin-bottom: 20px;
  }
  a {
    display: inline-block;
    margin-top: 30px;
  }
}

.uni_wrapper {
  position: relative;
}

.oval {
  position: absolute;
  z-index: 0;
  right: -12%;
  top: -15%;
}
.square {
  position: absolute;
  z-index: 0;
  bottom: -8%;
  left: -15%;
}

.image_wrapper {
  position: relative;
  z-index: 1;
  img {
    width: 100%;
    // animation: moveAndScale 1s infinite alternate;
    // animation-timing-function: ease-in-out;
  }
}

// .oval {
//   animation: moverUpDown 1s infinite alternate;
// }

// .square {
//   animation: moverLeftRight 1s infinite alternate;
//   animation-delay: 0.1s;
// }

@keyframes moverUpDown {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes moverLeftRight {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes moveAndScale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.01);
  }
}

@media (max-width: 1300px) {
  .oval {
    display: none;
  }
}

@media (max-width: 900px) {
  .section_unisearch_university {
    padding-top: 30px;
  }
  .square {
    display: none;
  }

  .title_wrapper {
    margin-right: 0px;
    max-width: 100%;
    .title {
      margin-bottom: 20px;
    }
    a {
      display: inline-block;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 600px) {
  .oval {
    display: none;
  }
}
